
import excursion from '../components/PopularResortsArticleImages/excursion.png'
import AllIclusive from '../components/PopularResortsArticleImages/AllIclusive.png'
import withKids from '../components/PopularResortsArticleImages/withKids.png'
import cheap from '../components/PopularResortsArticleImages/cheap.png'
import seasons from '../components/PopularResortsArticleImages/seasons.png'



import charterRegular from '../components/UsefularticlesImages/charterRegular.png';
import abroad from '../components/UsefularticlesImages/abroad.png';
import hotTours from '../components/UsefularticlesImages/hotTours.png';
import operatorAgent from '../components/UsefularticlesImages/operatorAgent.png';
import hotels from '../components/UsefularticlesImages/hotels.png';



export const useful = [
    {
        id: 1,
        title: "Туроператор та турагент",
        content: "В чому відмінність між туроператором і турагентом, і які ролі вони виконують",
        fullText: "Хто такий туроператор?\n" +
            "Туроператор - це юридична особа, яка отримала ліцензію на туроператорську діяльність та займається виключно:\n" +
            " - організацією та створенням  туристичного продукту;\n" +
            " - реалізацією та наданням  туристичних  послуг;\n" +
            " - посередницькою діяльністю із надання характерних та супутніх послуг.\n" +
            "Лише юридична особа, яка має ліцензію туроператора, має право включити до своєї назви слово «туроператор».\n" +
            "\n" +
            " Хто такий турагент?\n" +
            "Турагент - юридична особа або ФОП, яка здійснює посередницьку діяльність  з  реалізації  готового туристичного продукту туроператорів та туристичних послуг інших суб'єктів туристичної діяльності (реалізацію характерних та супутніх послуг)\n" +
            "\n" +
        "Якщо простими словами: \n" +
            "Туроператор - це компанія, яка створює та організує туристичні пакети, включаючи бронювання готелів, транспортні послуги та екскурсії. Туроператори розробляють тури та продають їх через турагентів.\n" +
            "\n" +
            "Турагент – це посередник, який продає тури, створені туроператорами. Турагенти надають консультації клієнтам, допомагають вибрати тур, адаптований до їхніх потреб, та оформляють всі необхідні бронювання.",
        imageUrl: operatorAgent
    },
    {
        id: 2,
        title: "Правила виїзду за кордон",
        content: "Точна інформація стосовно правил перетин кордону. Де її отримати?",
        fullText: "В Україні лише одна установа має повноваження надавати інформацію стосовно правил перетину кордону," +
            " як в мирний, так і в воєнний час: \n" +
            "\n" +
            "Прикордонна служба України  \n" +
            "\n" +
            "Жоден турагент чи туроператор таких повноважень не мають.\n" +
            "\n" +
            " Ви можете знайти контакти Прикордонної служби України на офіційному сайті: \n" +
            "\n" +
            " https://dpsu.gov.ua \n" +
            "\n" +
            " у розділі 'Контакти' \n" +
            "\n" +
            " І зателефонувати, або написати письмовий запит на емейл, стосовно інформації що Вас цікавить. \n" +
            "Відповідь краще роздрукувати і мати з собою при перетині кордону. \n" +
            "Як правило, відповідають доволі швидко.",
        imageUrl: abroad,
    },
    {
        "id": 3,
        "title": "Чартерні та регулярні рейси",
        "content": "В чому відмінність між чартерними та регулярними авіаперевезеннями",
        "fullText": "Чартери та регулярні авіаперевезення – у чому різниця.\n" +
            "Літаки літають по всьому світу щодня, у найвіддаленіші куточки планети." +
            " Напевно, ви чули, що рейси бувають регулярні та чартерні. " +
            "А ви знаєте, чим вони відрізняються? Хто винний, якщо рейс затримується? Давайте розберемося! " +
            "Регулярні та чартерні – у чому різниця?\n" +
            "\n" +
            "Регулярний рейс – це переліт, який виконують великі авіакомпанії. " +
            "Суворо за розкладом і цілий рік. Такі рейси не залежать від завантаження борту чи сезону. " +
            "Тобто, регулярний рейс полетить і із заповненим на третину салоном, і навіть якщо пасажирів буде всього 10 осіб." +
            " Компанію в цьому випадку не турбують збитки, головне – репутація. " +
            "Тому регулярні рейси затримуються або скасовуються вкрай рідко.\n" +
            " \n" +
            "Чартерний рейс – це політна програма, яку ставлять на короткий термін. " +
            "Найчастіше такі рейси організуються за певним напрямом у так званий високий сезон. " +
            "Наприклад, у літній період на острів Крит або взимку на курорти Єгипту. " +
            "Чартери можуть поставити декільки вильотів, а можуть закріпити політну програму на півроку. " +
            "Також чартер можуть скасувати, якщо борт завантажений менш ніж на половину," +
            " або виліт можуть перенести.\n" +
            "Чому відбуваються затримки?\n" +
            "Чартерні рейси ставляться в вільний період між польотами регулярних рейсів." +
            " Затримки в розкладі можуть відбуватися через погодні умови або через несправність літаків." +
            " Якщо таке відбувається, то першими відлітають регулярні рейси," +
            " а на пасажирів чартерних можуть чекати декілька годин, або навіть довше.",
        "imageUrl": charterRegular,
    },
    {
        "id": 4,
        "title": "Гарячі тури: що це таке, звідки беруться і коли їх купувати",
        "content": "Іноді туроператори пропонують клієнтам непогано заощадити: якщо ви готові на компроміси," +
            " тур зі знижкою – чудовий варіант, щоб не переплачувати. Розбираємо нюанси.",
        "fullText": "\"Гарячі\" тури (путівки) - це тури, які з'являються за 1-3 дні до початку туру." +
            " Дехто вважає, що «гарячі» тури є завжди, і для того, щоб купити тур дешево," +
            " потрібно просто прийти в агентство за 1-2 дні до вильоту і купити путівку. На жаль, це зовсім не так.\n" +
            " Формуванням туру займається лише туроператор, а агенція реалізує вже готовий турпродукт." +
            " Саме туроператор укладає договори з авіакомпанією, готелем, стороною, що приймає, і страховою компанією.\n" +
            "Туроператор уже викупив блоки місць у літаках та готелях на сезон уперед і сформував ціни на тури.\n" +
            "Якщо до дати вильоту залишається зовсім мало часу, а місць у літаку на конкретну дату ще достатньо, " +
            "туроператор починає знижувати ціни, і тоді з'являються спецпропозиції.\n" +
            "Якщо ж покупець слабо відреагував на зниження цін на тур, ціна продовжує падати і може дійти до рівня, " +
            "що «горить». Однак для туроператора зниження цін до «гарячих» турів — це крайній захід, до якого він вдається " +
            "лише в окремих випадках. Адже туроператор знижуючи ціну на тур до вартості авіаперельоту, зазнає збитків.\n" +
            "Не варто чекати на сильне зниження цін на популярні дати (свята, канікули, «оксамитовий сезон»). " +
            "Якщо Ви налаштовані відлетіти саме в ці дні, краще придбати путівку заздалегідь.\n" +
            "\n" +
            "У чому специфіка «гарячих» турів?\n" +
            "\n" +
            "Особливість турів, що «горять», полягає в тому, що до моменту появи спецпропозицій не в усіх готелях залишаються місця." +
            " Тому, якщо Ви налаштовані відпочити в конкретному готелі, краще подбати про бронювання туру наперед." +
            " Як правило, найкращі готелі у поєднанні «ціна-якість» розкуповуються насамперед.\n" +
            "Також слід пам'ятати, що якщо ви розраховуєте з'їздити в шикарний готель 5+," +
            " вартість якого в рази перевищує вартість авіаперельоту, то \"гарячий тур\"" +
            " в цей самий готель буде не таким, що горить в стандартному розумінні, так як знижена вартість на авіапереліт буде \"краплею в морі\" " +
            "по відношенню до повної ціни туру.\n" +
            "По-справжньому «тури, що горять» розкуповуються дуже швидко, тому, якщо тур дійсно «гарячий», " +
            "важливо встигнути його забронювати.\n" +
            "\n" +
            "В який час найімовірніші «гарячі» тури?\n" +
            "\n" +
            "Це можуть бути тури в період низького сезону, перед святами або після них, " +
            "у періоди несприятливих метеоумов тощо. Наприклад, тури на Новий Рік, як відомо," +
            " коштують набагато дорожче, ніж у звичайні дати. Але, якщо Ви поїдете на відпочинок на початку" +
            " грудня або наприкінці січня, у Вас є шанс значно заощадити гроші," +
            " тому що ці дати не користуються попитом у туристів.\n" +
            "\n" +
            "Підсумовуючи все сказане вище, можемо порадити вам бронювати тури заздалегідь, " +
            "якщо ви звикли до комфорту, летите разом з сім'єю або дітьми. Якщо ж ви «відчайдушні» " +
            "мандрівники, готові вирушити в дорогу будь-якої хвилини і в будь-який запропонований готель, " +
            "то «гарячі тури» - чудова можливість заощадити на відпочинку та отримати незабутні враження!",
        "imageUrl": hotTours,
    },
    {
        id: 5,
        title: "Типи харчування та номерів в готелях",
        content: "На що варто звернути увагу при бронюванні готелю",
        fullText: "Номери:  \n" +
            " Apartment – апартаменти, аналог квартири, що складається з кількох кімнат і кухні;\n" +
            "Balcony – з балконом;\n" +
            "BDR, BDRM (bedroom) – зі спальнею;\n" +
            "Business – види номерів в готелі з великою площею, комп'ютером, факсом, призначені для роботи;\n" +
            "Connected rooms – суміжні кімнати, які мають між собою двері;\n" +
            "De luxe – двомісний однокімнатний номер великого розміру з більш дорогою обстановкою мінімум з двох кімнат;\n" +
            "Duplex – великий двоповерховий номер;\n" +
            "Extra bed – додаткове ліжко;\n" +
            "King size – з великим двоспальним ліжком;\n" +
            "Family room – сімейна кімната (більше стандартної);\n" +
            "Family studio – сімейний, складається з двох кімнат;\n" +
            "Honeymoon room – типи кімнат у готелях, які призначені для молодят;\n" +
            "President – найшикарніші типи номерів у готелі, що складаються з декількох спальних кімнат, вітальні, кабінету, з декількома ванними кімнатами та туалетами;\n" +
            "STD (standart) – стандартна кімната;\n" +
            "Studio – студія (однокімнатний номер з кухнею);\n" +
            "Suite – номер, що складається з вітальні та спальні;\n" +
            "Suite mini (junior) – покращений;\n" +
            "Superior – покращений (більшого розміру, ніж стандартний).\n" +
            "\n" +
            "BGL, BG (Bungalow) – бунгало; будова, що стоїть окремо від основної будівлі. Частіше за все одно- або двоповерхова;\n" +
            "Cabana – будиночок на пляжі (біля басейну) за типом бунгало;\n" +
            "Chale – прибудова до основної будівлі або будиночок, який стоїть окремо;\n" +
            "Cottage – котедж;\n" +
            "Executive Floor – один або кілька поверхів у готелі з більш високим рівнем обслуговування;\n" +
            "HV (holiday village) – комплекс бунгало або невеликих будівель;\n" +
            "MB (Main Building) – основна будівля готелю;\n" +
            "Villa – вілла.\n" +
            "\n" +
            "BV (Beach view) – на пляж;\n" +
            "CV (City view) – на місто;\n" +
            "DV (Dune view) – на дюни, піски;\n" +
            "GV (Garden view) – на сад;\n" +
            "IV (Inside view) – на внутрішню частину готелю;\n" +
            "LV (Land view) – на околиці;\n" +
            "MV (Mountain view) – на гори;\n" +
            "OV (Ocean view) – на океан;\n" +
            "PV (Park view) – на парк;\n" +
            "PV (Pool view) – на басейн;\n" +
            "SSV (Side Sea view) – з боковим видом на море;\n" +
            "SV (Sea view) – на море;\n" +
            "VV (Valley view) – на долину;\n" +
            "ROH (run of the house) – вид з вікна і тип розміщення в готелі не уточнюється.\n" +
            "\n" +
            "SNGL (single) – одномісний;\n" +
            "DBL (double, double twin) – двомісне розміщення з одним двоспальним або двома окремими ліжками;\n" +
            "TWN (Twin) – двомісне розміщення з двома односпальними ліжками;\n" +
            "TRPL (triple) – тримісне розміщення (зазвичай двомісний номер з двома ліжками + додатково розкладається ліжко або диван);\n" +
            "QDPL (quadriple) – чотиримісне розміщення;\n" +
            "ExB (extra bed) – можливість встановлення додаткового ліжка;\n" +
            "Chld (child) – вартість проживання дитини до 12-ти років. Іноді позначають CH – велика дитина (до 12-15 років); " +
            "ch – маленька дитина (до 6 років), inf – дитина до 2-х років.\n" +
            "\n" +
            "Харчування:  \n" +
            "\n" +
        " RO (Room Only), AO (Accommodation Only), NO (без харчування)," +
            " EP (European Plan) — харчування в готелі не передбачено. " +
            "Це означає, що вам доведеться постійно харчуватися в кафе або ресторанах." +
            " Підійде тільки тим, хто хоче познайомитися з місцевою, традиційною кухнею в колоритних і цікавих закладах.\n" +
            "\n" +
            " BB (Bed & Breakfast) — абревіатура харчування в готелях," +
            " де у вартість номера включений тільки сніданок. \n" +
            "Сніданки бувають декількох видів:\n" +
            "CB (Continental Breakfast) — континентальний (дуже легкий) вид сніданків." +
            " Вам запропонують чай/каву/сік і булочку з маслом і джемом. " +
            "Іноді до стандартного набору можуть додати йогурт, мюслі і дуже рідко шинку або сир. " +
            "Така система харчування в готелях підійде тим, хто не любить ситно снідати вранці.\n" +
            "AB (American Breakfast) — американський чи англійський сніданки. " +
            "Ці види харчування в готелях зустрічаються частіше і користуються більшою популярністю. " +
            "До стандартного набору вам додадуть сосиски, бекон, печериці, помідори, яєчню і квасолю.\n" +
            "\n" +
            " HB (Half Board) — напівпансіон і HB+. Типи харчування в готелі," +
            " коли у вартість проживання включений сніданок і вечеря. Різниця між HB і HB+ полягає в тому, що HB+ " +
            "включає ще алкогольні та безалкогольні напої.\n" +
            "\n" +
            " FB (Full Board) — повний пансіон і FB+. Тип харчування в готелях," +
            " який передбачає триразові повноцінні прийоми їжі. FB+ так само, як і HB+ включає напої.\n" +
            "\n" +
            "\n" +
            " AI (All Inclusive) і UAI (Ultra All Inclusive). " +
            "Це відомі всім туристам системи харчування в готелях, " +
            "які передбачають систему «все включено». Крім стандартних сніданку," +
            " обіду і вечері, вам можуть пропонувати ланчі, полуденки і другі сніданки, а також напої." +
            " Тип харчування Ultra All Inclusive відрізняється тим, " +
            "що вам додатково запропонують всілякі десерти, солодощі та напої на будь-який смак.",
        imageUrl: hotels,
    },


];

export const resorts = [
    {
        id: 1,
        title: "Курортні сезони в країнах",
        content: "Куди в коли краще їхати на відпочинок",
        fullText: "Календар мандрівника \n" +
            "\n" +
            "Січень, лютий\n" +
            "Пляжний відпочинок: Єгипет, Тайланд, В'єтнам, Індія, ОАЕ, Шрі-Ланка, " +
            "Мексика, Домінікана, Куба, Мальдіви, Сейшели, Кенія, " +
            "Танзанія, Канари, Маврикій, Мексика \n" +
            "" +
            "В січні є багато можливостей чудово відпочити, хоча на початку місяця ціни, як правило, високі із-за свят," +
            " а всі хороші готелі заброньовані." +
            " Ви можете поїхати на гірськолижні курорти, відпочити біля теплого моря в далеких країнах, " +
            "або побувати на екскурсіях у цікавих місцях. Якщо вийде запланувати поїздку на другу половину січня, або на лютий" +
            " то зможете суттєво заощадити.\n" +
            "Пляжний відпочинок на цей період найдоступніший в Єгипті та ОАЕ, але краще вирушати подалі – до Таїланду," +
            " на Мальдіви, Кубу, Домінікану, Індію, Шрі-Ланку, Китай, В'єтнам, в Мексику та до інших тропічних курортів." +
            " З гірськолижних курортів найбільш популярні Австрія та Андорра, хоча чудові траси також є в Італії, Франції," +
            " Швейцарії, Туреччині, Болгарії, Іспанії, Польщі та інших країнах. " +
            "Окрім екскурсій Європою в січні можна відправитися на екскурсії подалі " +
            "– до Індії, Непалу, Йорданії та Ізраїлю, тощо \n" +
            "\n" +
           "Березень" +
            "\n" +
               "Пляжний відпочинок: Єгипет, Тайланд, В'єтнам, Індія, ОАЕ, Шрі-Ланка, " +
            "Мексика, Домінікана, Куба, Мальдіви, Сейшели, Кенія, " +
            "Танзанія, Канари, Маврикій, Мексика n/ " +
               "" +
            "У березні ще рано вирушати на найближчі морські курорти, " +
            "а на далеких напрямках сезон скоро закінчиться, " +
            "але поки ще тримається хороша погода і пропонуються тури за помірними цінами.\n" +
            "Хороша погода для відпочинку у Єгипті, зимові вітри вже припинились. " +
            "Також можна відпочити в ОАЕ, Йорданії чи Марокко. З далеких країн користуються популярністю Таїланд, " +
            "Індія, Шрі-Ланка, Китай, В'єтнам, Мальдіви, Філіппіни, Бразилія, Мексика, Кенія, Куба і Домінікана.\n" +
            "\n" +
            "Квітень\n" +
            "Пляжний відпочинок: Єгипет, Тайланд, В'єтнам, Індія, ОАЕ, Шрі-Ланка, " +
            "Мексика, Домінікана, Куба, Мальдіви, Сейшели, Кенія, " +
            "Танзанія, Канари, Маврикій, Мексика, початок сезону в Туреччині, на Кіпрі. \n" +
            "В квітні у мандрівників великий вибір – можна вирушити в далекі країни, а можна відпочити набагато ближче," +
            " адже в найпопулярнішимх напрямках починається сезон.\n" +
            "У квітні вже можна вирушати до Туреччини, хоча поки що тут часом прохолодно," +
            " зате в Єгипті стоїть чудова погода, не така спекотна, як влітку, " +
            "але й не прохолодна, як узимку. Також можна відмінно відпочити в ОАЕ," +
            " Йорданії, Ізраїлі або Марокко. Прекрасна погода стоїть на Кубі, в  " +
            "Мексиці та Домінікані, ідеальний час для відпочинку в Південно-Східній та Південній Азії" +
            " – в Таїланді, Китаї, В'єтнамі, Індії та Шрі-Ланці. Особливо приємний відпочинок на екзотичних островах" +
            " - Мальдівах, Маврикії, Сейшелах. Також можливі екскурсії по Європі та заняття гірськими" +
            " лижами на низці європейських курортів.\n" +
        "\n" +
        "Травень\n" +
                "Пляжний відпочинок: Туреччина, Єгипет, Туніс, Кіпр, Ізраїль, Іспанія, Греція, Італія, Хорватія," +
            " Чорногорія, Болгарія Індонезія," +
            " Сейшели, Малайзія, Канари, Маврикій," +
            " Мальта, Марокко \n" +
            "Травень ще не можна назвати повноціним літнім місяцем, " +
            "але на більшості найближчих курортів вже настав найкращий час для відпочинку. " +
            "У багатьох країнах півдня Європи стоїть спекотна погода, але не виснажлива, а море достатньо прогрілося для купання." +
            " Також настав найкращий час для екскурсій Європою.\n" +
            "Найпопулярніша Туреччина пропонує свої курорти, в Єгипті стоїть чудова погода, не дуже спекотна," +
            " готовий прийняти гостей Ізраїль. " +
            "Практично всі європейські країни, розташовані на Середземному та Чорному морях, готові прийняти відпочиваючих. " +
            "Ви можете відправитися до Іспанії, Греції, Італії, Хорватії, Чорногорії, Болгарії, а також на Кіпр." +
            " Для любителів таласотерапії рекомендується відвідати Туніс. " +
            "З далеких напрямків сприятлива погода у В'єтнамі, на островах Балі та Маврикій, Сейшелах та на Мадагаскарі.\n" +
            "\n" +
            "Червень\n" +
            "Пляжний відпочинок: Албанія, Болгарія, Греція, Грузія, Індонезія, Іспанія, Італія, Кіпр, Морокко," +
            " Португалія, Сейшели, Туніс, Франція, Хорватія, Чорногорія, Малайзія, Танзанія, Канари, Майорка, Мальта, Португалія.\n" +
            "Червень знаменує початок літа, при цьому ще не настав пік відпусток, " +
            "тож на популярних курортах ще не так багатолюдно, а ціни не досягли свого максимума.\n" +
            "Найбільш популярним є відпочинок у Туреччині, а в Єгипті вже наступають досить спекотні дні. " +
            "Найкращий сервіс пропонують європейські курорти в Іспанії, Греції, Італії, Хорватії, Чорногорії, Болгарії, на Кіпрі, " +
            "можна вирушити на рибалку до Фінляндії. Також потрібна погода для відпочинку на Мальті та в Тунісі." +
            " З далеких напрямів можна побувати на екзотичних островах – Балі, Маврикії чи Мадагаскарі.\n" +
             "\n" +
                "Липень\n" +
            "Пляжний відпочинок: Албанія, Болгарія, Греція, Грузія, Індонезія, Іспанія, Італія, Кіпр, Морокко," +
            " Португалія, Сейшели, Туніс, Франція, Хорватія, Чорногорія, Малайзія, Танзанія, Канари, Майорка, Мальта, Португалія.\n" +
            "З настанням липня починається сезон відпусток, тури на популярні курорти дорожчають," +
            " а місця у найкращих готелях давно розібрані. При цьому на більшості курортів стоїть спекотна погода, " +
            "а на деяких навіть дуже спекотна." +
            " Найбільшою популярністю користуються курорти Туреччини," +
            " але приємніша погода на європейських курортах Середземного моря – в Іспанії, Греції, Італії, Хорватії," +
            " Чорногорії та на Кіпрі. Також приємна погода стоїть на чорноморському узбережжі Болгарії, Грузії." +
            " Для любителів далеких подорожей можна відзначити острови Балі та Мадагаскар, а також Кенію. " +
            "У липні 2024 року занадто спекотно для екскурсій Центральною Європою, але можна відвідати Скандинавські країни.\n" +
            "\n"+
            "Серпень\n" +
            "Пляжний відпочинок: Албанія, Болгарія, Греція, Грузія, Індонезія, Іспанія, Італія, Кіпр, Морокко," +
            " Португалія, Сейшели, Туніс, Франція, Хорватія, Чорногорія, Малайзія, Танзанія, Канари, Майорка, Мальта, Португалія.\n" +
            "У серпні триває сезон відпусток, і на більшості популярних курортів дуже багато відпочиваючих." +
            " Хоча вже не так спекотно, як у липні, на узбережжі зберігається тепла сонячна погода.\n" +
            "Прекрасний відпочинок пропонують курорти Туреччини та південних європейських країн." +
            " У другій половині місяця можна вирушати в екскурсії Європою. " +
            "Для любителів екзотичних далеких країн можна порекомендувати Балі та Кенію.\n" +
           "\n"+
            "Вересень\n" +
            "Пляжний відпрчинок: Туреччина, Єгипет, Греція, Кіпр, Туніс, Болгарія, Грузія," +
            " Ізраїль, Іспанія, Італія, Чорногорія, Хорватія, Франція, Індонезія, Сейшели, Малайзія," +
            " Марокко, Кенія, Танзанія, Албанія, Португалія, Канари, Майорка, Мальта, Маврикій.\n" +
            "Вересень – найкращий час для відпочинку біля моря." +
            " Більшість ввідпочиваючих повернулися з відпусток, на курортах не так багатолюдно, " +
            "ціни знизилися, спека спала, але все ще тепло для загоряння та купання." +
            " У цей час можна вирушати і на екскурсії Європою.\n" +
            "На популярних курортах Туреччини та Єгипту стоїть чудова погода, " +
            "як і на півдні Європи або в Тунісі та Ізраїлі. З далеких напрямків сприятлива погода для відпочинку на Балі, " +
            "Маврикії, Мальті та Кенії.\n" +
            "\n"+
            "Жовтень\n" +
            "Пляжний відпочинок: Туреччина, Єгипет, Туніс, Кіпр, ОАЕ, Ізраїль, Індія" +
            " Сейшели, Малайзія, Кенія, Марокко, Танзанія, Канари, Маврикій, Мальта, Шрі Ланка\n" +
            "Жовтень є останнім місяцем в році, коли в найближчих країнах ще можна відпочивати на морі." +
            "При цьому покращується погода в далеких країнах, тому в жовтні досить великий вибір місць для відпочинку.\n" +
            "У Туреччині ще можна відпочивати на пляжах, а в Єгипті встановлюється оптимальна погода для пляжного відпочинку." +
            " Хоча в Європі вже не так спекотно, на островах, що належать європейським країнам, можна чудово відпочити. " +
            "Також можливий відпочинок у Тунісі, Марокко, Ізраїлі, ОАЕ, розпочинається сезон в Індії на курорті Гоа та на Шрі-Ланці. " +
            "Хороша погода стоїть на Маврикії, Сейшелах та Кенії.\n" +
            "\n"+
            "Листопад\n" +
            "Пляжний відпочинок: Єгипет, Тайланд, Індія, ОАЕ, Хайнань, Шрі-Ланка, Ізраїль, " +
            "Куба, Домінікана, Мальдіви, Сейшели, Мексика, Канари, Маврикій.\n" +
            "З приходом листопада найбільш популярними стають курорти в спекотних країнах, більшість з яких розташовані досить далеко. " +
            "У Європі відкривається гірськолижний сезон, хоча на початку місяця не всі гірськолижні курорти відкриті.\n" +
            "В Єгипті настає ідеальна погода для відпочинку, як і в ОАЕ. Також можна відпочити в Марокко чи Йорданії." +
            " Зростає потік туристів до Південно-Східної та Південної Азії, причому особливою популярністю користується Таїланд." +
            " Також багато хто відправляється в Індію, Шрі-Ланку, на острів Хайнань у Китаї, або на Мальдіви. " +
            "Прекрасна погода стоїть і на островах Карибського моря – в Домінікані та на Кубі." +
            " Любителі екзотики можуть вирушити на Маврикій, Сейшели чи Кенію. З далеких напрямів можна оббрати Бразилію.\n" +
            "\n"+
            "Грудень\n" +
            "Пляжні напрямки: Тайланд, В'єтнам, Індія, ОАЕ, Єгипет, Шрі-Ланка, " +
            "Куба, Домінікана, Мальдіви, Сейшели, Мексика, Танзанія, Канари, Маврикій.\n" +
            "Грудень – чудовий час для відпочинку. Прекрасна погода на численних морських курортах," +
            " а в Європі повноцінно працюють гірськолижні. Наприкінці місяця, перед Новим Роком, ціни всюди різко зростають.\n" +
            "У Єгипті ще можна відпочивати, хоч там уже не так жарко." +
            " Також можна відправитися на відпочинок до ОАЕ, Йорданії або Марокко. " +
            "З далеких напрямів популярні Таїланд, Індія, Шрі-Ланка, Мальдіви, Куба і Домінікана." +
            " Можна вирушити до Кенії, на Сейшели чи Багами. Чекають на гостей гірськолижні курорти в Австрії, Андоррі, Італії, " +
            "Франції, Швейцарії, Туреччині, Болгарії, Іспанії, Польщі. Для екскурсій Європою холоднувато," +
            " але можна поєднати пляжний відпочинок з екскурсіями в далеких країнах - Індії, Мексиці, Непалі, Йорданії, Таїланді."
        ,

        imageUrl: seasons,
    },
    {
        id: 2,
        title: "Бюджеті напрямки для відпочинку",
        content: "Куди можна поїхати з мінімальним бюджетом",
        fullText: "Найвигіднішими, як правило виходять пакетні тури на чартерних рейсах, " +
            "з системою все включено (як варіант 2-х або 3-х разове харчування), на недалекі напрямки.\n" +
            " Можна також чудово зекономити, якщо замість авіа перельоту обрати проїзд автобусом " +
            "(Туреччина, Болгарія, Чорногорія, Албанія, Хорватія)," +
            " але враховуйте, що дорога займе більше часу. \n" +
            "\n" +
            "Болгарія - чудовий економ варіант, працює на все включено, недалеко їхати автобусом.\n" +
            "Туреччина - країна пропонує різноманіття готелів, є багато бюджетних варіантів. Є все включено " +
            "і також можна дістатись автобусом.\n" +
            "Єгипет - багато недорогих готелів, все включено.\n" +
            "Чорногорія - на все включено готелі не працюють, але харчування зовсім недороге. Готелі також. Можна дістатись автобусом\n" +
            "Грузія - У Грузії недорого, красиво та дуже колоритно." +
            "На все включено забронюватись не вийде, але харчуватись на місці зовсім не дорого.\n" +
            "Кіпр, Греція  -  вартість відпочинку трохи дорожче Туреччини\n" +
            "ОАЕ - можна знайти хороші ціни на тури, для економії варто обирати тур з харчуванням одразу. Ціни в країні високі."
        ,
        imageUrl: cheap,
    },
    {
        id: 3,
        title: "Країни для відпочинку з дітьми",
        content: "Куди поїхати з малечею",
        fullText: "При виборі місця відпочинку з дітьми за кордоном враховуйте:\n" +
            "\n" +
            "Вибирайте курорти, до яких не треба довго летіти. Обирайте прямі рейси.\n" +
            "Курорт з розвиненою інфраструктурою підійде старшим дітям, а малюкам, навпаки, тихий і усамітнений.\n" +
            "При потребі уточніть, чи є в готелі дитяче меню, розваги для дітей: як то дитяча анімація, аквапарки і таке інше.\n" +
            "\n" +
            "Гарно підійдуть напрямки:\n" +
            "\n" +
            "Туреччина\n" +
            "Болгарія\n" +
            "Хорватія\n" +
            "Греція\n" +
            "Кіпр\n" +
            "Іспанія\n" +
            "Туніс\n" +
            "Таїланд\n" +
            "ОАЕ"
            ,
        imageUrl: withKids,
    },
    {
        id: 4,
        title: "Напрямки, які працюють на 'Все включено'",
        content: "Комфортний відпочинок без турбот",
        fullText: "Що таке All Inclusive?"+
            "\n" +

        "All Inclusive, скорочено AI, - це система комплексного обслуговування в готелі," +
            " під якою найчастіше розуміють великий і смачний шведський стіл з безперервним доступом. \n" +
"Ол інклюзів може бути різним – з незначним або великим вибором. " +
    "Наприклад, у Туреччині та Єгипті шведський стіл ломиться від закусок, " +
    " В Іспанії чи Греції їжа дуже смачна, але вибір зовсім невеликий. " +
    "До того ж, якщо пропустити обід, доведеться чекати аж на вечерю. " +
            "Можливий варіант, коли вам приносять меню і ви обираєте страви, замість шведського стола." +
    "У Європі AI часто практично збігається із FB (Повний пансіон, або трьохразове харчування)."+
            "\n" +
"Часто, особливо в Туреччині, можна зустріти позначення UAI або Ultra All Inclusive. "+
            "Це означає, що до пакету входять якісь додаткові послуги та алкоголь відомих марок. "+
        "Така форма обслуговування зазвичай використовується у великих клубних готелях. \n" +
            "\n" +
            "Традиційно на все включено працюють:"+
            "\n" +
            "Туреччина\n" +
            "Болгарія\n" +
            "Єгипет\n" +
            "Туніс\n" +
            "ОАЕ\n" +
            "Греція\n" +
            "Кіпр\n" +
            "Албанія\n" +
            "Домінікана\n" +
            "Мальдіви"

        ,
        imageUrl: AllIclusive,
    },
    {
    id: 5,
        title: "Найпопулярніші екскурсійні напрямки (Європа)",
        content: "Як провести відпочинок з користю",
        fullText: "Підбірка міст: \n" +
            "\n" +
        "Венеція (Італія)"+
            "\n" +
        "Рим (Італія)"+
            "\n" +
        "Париж (Франція)"+
            "\n" +
        "Відень (Австрія)"+
            "\n" +
        "Мюнхен (Німеччина)"+
            "\n" +
        "Копенгаген (Данія)"+
            "\n" +
        "Прага (Чехія)"+
            "\n" +
        "Барселона (Іспания)"+
            "\n" +
        "Ліссабон (Португалія)"+
            "\n" +
        "Дубровник (Хорватія)"+
            "\n" +
        "Стамбул (Туреччина)"+
            "\n" +
            "Будапешт (Угорщина)"

        ,
        imageUrl: excursion,
},




]


